import { app, pages } from "@microsoft/teams-js";
import HostSettings from "../../../utils/host.settings";

export default function navigateTo(tab: string, customParam?: string) {
  if (pages.currentApp?.isSupported()) {
    // This is the case for New Teams version
    pages.currentApp.navigateTo({
      pageId: tab,
      subPageId: customParam,
    });
  } else {
    let url = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/${tab}`;
    if (customParam) {
      url += `?subPageId=${customParam}`;
    }
    app.openLink(url);
  }
}
