/* eslint-disable */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  SecurityType,
  TeamsUserCredential,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Open16Regular } from "@fluentui/react-icons";
import {
  Box,
  Button,
  CheckboxProps,
  Flex,
  Loader,
  ProviderConsumer,
  Text,
} from "@fluentui/react-northstar";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import NotificationSettings, {
  ScheduleExpenseStatements,
  ScheduleShareMyTransfer,
  ScheduleUnassignedExpenses,
} from "../../../common/models/NotificationSettings";
import RedirectionInput from "../../../common/models/redirection/RedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import SecurityComponent from "../../../common/security";
import RedirectionService from "../../../services/redirection/RedirectionService";
import Store from "../../../store/Store";
import { useAppSelector } from "../../../store/StoreHooks";
import {
  isLoggedInSelector,
  userObjectIdSelector,
} from "../../../store/authentication/AuthenticationSelector";
import { selectFeatureToggle } from "../../../store/feature-toggles/FeatureTogglesSelector";
import {
  loadNotificationSettings,
  setNotificationSettings,
} from "../../../store/notification-settings/NotificationSettingsActions";
import {
  isChatAssistantEnabled,
  isNotificationLoadDoneSelector,
  isNotificationPendingSelector,
  scheduleSmrSelector,
  statusChangeSelector,
} from "../../../store/notification-settings/NotificationSettingsSelector";
import CheckFeatureToggle from "../../../utils/CheckFeatureToggle";
import {
  CYTRIC_EASY_BUILD,
  FeatureToggleDefinition,
} from "../../../utils/constants";
import { isMobile } from "../../../utils/device.utils";
import HostSettings from "../../../utils/host.settings";
import setSettings from "../functions/setSettings";
import AccountSettingsOptions from "./AccountSettingsOptions";
import SettingsOption from "./SettingsOption";
import SettingsOptionsStyles from "./SettingsOptions.module.scss";

export interface SettingsOptionsProps {
  logoutFcn: () => void;
}

function SettingsOptions() {
  const [isStatusChangeNotificationOn, setIsStatusChangeNotificationOn] =
    useState(false);
  const [isStatusSmrSettingsOn, setIsStatusSmrSettingsOn] = useState(false);
  const [isUserProfileAllowed, setIsUserProfileAllowed] = useState(false);
  const [isUserProfileAllowedLoading, setIsUserProfileAllowedLoading] =
    useState(true);

  const [isStatusAIChatBotSettingOn, setIsStatusAIChatBotSettingOn] =
    useState(false);

  const [isCytricAiBotSettingsEnabled, setIsCytricAiBotSettingsEnabled] =
    useState(false);
  const [userSystemName, setUserSystemName] = useState("");

  const { t } = useTranslation("translation");

  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );

  const userObjectId: string = useAppSelector((state) =>
    userObjectIdSelector(state)
  );

  const isLoading: boolean = useAppSelector((state) =>
    isNotificationPendingSelector(state)
  );

  const isInitialLoadDone: boolean = useAppSelector((state) =>
    isNotificationLoadDoneSelector(state)
  );

  const isStatusChangeOn: ScheduleExpenseStatements | null = useAppSelector(
    (state) => statusChangeSelector(state)
  );

  const isSmrSettingsOn: ScheduleShareMyTransfer | null = useAppSelector(
    (state) => scheduleSmrSelector(state)
  );

  const isChatAssistantEnabledSettingOn: boolean = useAppSelector((state) =>
    isChatAssistantEnabled(state)
  );

  const isAuthAiBotSettingsEnabled = useAppSelector((state) =>
    selectFeatureToggle(
      state,
      FeatureToggleDefinition.enableAuthAiBotSettings.id
    )
  );

  const enableAiChatBotForSystemNames = useAppSelector((state) =>
    selectFeatureToggle(
      state,
      FeatureToggleDefinition.enableAIChatBotForListOfSystems.id
    )
  );

  const enableAIChatBotForListOfUsers = useAppSelector((state) =>
    selectFeatureToggle(
      state,
      FeatureToggleDefinition.enableAIChatBotForListOfUsers.id
    )
  );

  const enableCytricClassicSupportLink = useAppSelector((state) =>
    selectFeatureToggle(
      state,
      FeatureToggleDefinition.supportLinkInSettingsPage.id
    )
  );

  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPSettings.id
  );

  const isMobileDevice = isMobile();

  const history = useHistory();

  const setSettingsOnClick = (
    _e: any,
    checked: CheckboxProps | undefined,
    setting: string
  ) => {
    const settingsObject: NotificationSettings = {
      scheduleUnassignedExpenses: ScheduleUnassignedExpenses.Always,
      scheduleExpenseStatements: isStatusChangeOn,
      scheduleShareMyTransfer: isSmrSettingsOn,
      isChatAssistantEnabled: isChatAssistantEnabledSettingOn,
    };

    const requestSettingsObject: NotificationSettings = setSettings(
      settingsObject,
      checked,
      setting
    );

    Store.dispatch(setNotificationSettings(requestSettingsObject));
  };

  useEffect(() => {
    if (isLoggedIn) {
      Store.dispatch(loadNotificationSettings());
      retrieveUserSystemName();
      (async () => initializeNotificationSettings())();
    }
  }, [isLoggedIn]);

  const retrieveUserSystemName = () => {
    const systemName = localStorage.getItem("profile.system");
    if (systemName) {
      setUserSystemName(systemName);
    }
  };

  const initializeNotificationSettings = async () => {
    const credential = new TeamsUserCredential();
    const token = await credential.getToken("cytric-easy");
    const aiBotActivationPermissionStatus = token?.permissions?.some(
      (p) => p.name === "activationAiBot" && p.status
    );
    setIsCytricAiBotSettingsEnabled(!!aiBotActivationPermissionStatus);
  };

  useEffect(() => {
    if (ScheduleExpenseStatements.Never === isStatusChangeOn) {
      setIsStatusChangeNotificationOn(false);
    } else {
      setIsStatusChangeNotificationOn(true);
    }
  }, [isStatusChangeOn]);

  useEffect(() => {
    if (ScheduleShareMyTransfer.Never === isSmrSettingsOn || !isSmrSettingsOn) {
      setIsStatusSmrSettingsOn(false);
    } else {
      setIsStatusSmrSettingsOn(true);
    }
  }, [isSmrSettingsOn]);

  useEffect(() => {
    if (isChatAssistantEnabledSettingOn) {
      setIsStatusAIChatBotSettingOn(true);
    } else {
      setIsStatusAIChatBotSettingOn(false);
    }
  }, [isChatAssistantEnabledSettingOn]);

  useEffect(() => {
    // Avoid to call redirection service on TRIPPSettings users
    if (isTRIPPUser) {
      setIsUserProfileAllowedLoading(false);
    } else {
      const dashboardInput: RedirectionInput = {
        segmentType: RedirectionTypeEnum.USER_PROFILE,
      };
      RedirectionService.getCytricUrl(dashboardInput)
        .then((result) => {
          setIsUserProfileAllowed(result?.data?.data?.cytricUrl);
        })
        .finally(() => {
          setIsUserProfileAllowedLoading(false);
        });
    }
  }, [isTRIPPUser]);

  const releaseVersionNumber = () => {
    return (
      <Text
        content={`${CYTRIC_EASY_BUILD} ${HostSettings.getAppReleaseVersionNumber}`}
      />
    );
  };

  const renderShareMyRideGlobalSettings = () => {
    return (
      <>
        {
          <SettingsOption
            optionTitle={t("Settings.notifications.ShareMyRide")}
            optionCheck={isStatusSmrSettingsOn}
            onChangeType={"sharemyride"}
            optionLabel={t("Settings.accessibility.notifications.ShareMyRide")}
            setSettingsOnClick={(
              _e: any,
              checked: CheckboxProps | undefined,
              setting: string
            ) => {
              telemetryService.trackEvent(
                {
                  name: "travel-shareRide-globalActivation",
                },
                {
                  isActivated: checked?.checked,
                }
              );
              setSettingsOnClick(_e, checked, setting);
            }}
          />
        }
      </>
    );
  };

  const isAllowedToUseAIChatBot = () =>
    (isAuthAiBotSettingsEnabled?.isActive
      ? isCytricAiBotSettingsEnabled
      : enableAiChatBotForSystemNames?.status?.includes(userSystemName)) ||
    enableAIChatBotForListOfUsers?.status
      ?.split(",")
      .map((s) => s.trim())
      .includes(userObjectId);

  const renderEnableAIChatBotSetting = () => (
    <>
      {isAllowedToUseAIChatBot() && (
        <SettingsOption
          optionTitle={t("Settings.notifications.AIChatBot")}
          optionCheck={isStatusAIChatBotSettingOn}
          onChangeType={"enablechatassistance"}
          optionLabel={t("Settings.accessibility.notifications.AIChatBot")}
          setSettingsOnClick={(
            _e: any,
            checked: CheckboxProps | undefined,
            setting: string
          ) => {
            telemetryService.trackEvent(
              {
                name: "settings-aichatbot-toggle-clicked",
              },
              {
                isActivated: checked?.checked,
              }
            );
            setSettingsOnClick(_e, checked, setting);
          }}
        />
      )}
    </>
  );

  const learningHubLink = (
    <>
      <Flex
        className={SettingsOptionsStyles.subtitle}
        column={isMobileDevice}
        hAlign="center"
      >
        <Text>{t("LoginComponent.needMoreInfo")} </Text>
        <Button
          text
          role="link"
          primary
          className={SettingsOptionsStyles.link}
          content={`${t("LoginComponent.findOutNow")}`}
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
          })}
          onClick={() => {
            window.open(
              HostSettings.getUrlLearningPortal,
              "_blank",
              "noopener noreferrer"
            );
          }}
          iconPosition="after"
          icon={<Open16Regular data-testid="external-link-icon-learning-hub" />}
        />
      </Flex>
      <Box
        className={`${SettingsOptionsStyles.divider} ${SettingsOptionsStyles.marginBottom}`}
        styles={(theme) => ({
          color: theme.theme.siteVariables
            ? theme.theme.siteVariables.settings?.divider
            : "",
        })}
      />
    </>
  );

  const redirectCytricClassic = () => {
    history.push(
      `/classic?segmentType=${RedirectionTypeEnum.SUPPORT_PAGE}&type=settings`
    );
  };

  const renderSupportPage = () => (
    <Flex space="between" gap="gap.small" vAlign="start" column>
      <Text content={t("Settings.support.description")} />
      <Button
        className="support"
        styles={(theme) => ({
          color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
        })}
        text
        primary
        onClick={() => {
          redirectCytricClassic();
        }}
        size="small"
        content={t("Settings.support.support_link")}
        aria-label={t("Settings.support.support_link")}
        data-testid="cytric-classic-support-page"
      />
    </Flex>
  );

  if (isLoggedIn) {
    return (
      <>
        {isLoading || isUserProfileAllowedLoading ? (
          <Loader
            className={SettingsOptionsStyles.loading}
            styles={(theme) => ({
              backgroundColor: isMobileDevice
                ? theme.theme.siteVariables?.colorScheme?.default.background
                : "",
            })}
            data-testid="loadingSpinner"
            label={t("spinner.waitAMoment")}
          />
        ) : (
          <>
            <div className="settingsLayout">
              <Flex column styles={{ minHeight: "80%" }}>
                <Text className={"headerTitle"} weight="bold">
                  {t("Settings.titles.Settings")}
                </Text>
                <ProviderConsumer
                  render={() => (
                    <AccountSettingsOptions
                      isUserProfileAllowed={isUserProfileAllowed}
                    />
                  )}
                />
                <SecurityComponent
                  securityRole={SecurityType.activationExpenses}
                >
                  <Flex style={{ backgroundColor: "transparent" }}></Flex>
                  <Flex
                    className="sectionTitle"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Text weight="bold">
                      {t("Settings.titles.Notifications")}
                    </Text>
                  </Flex>
                  <Box
                    className={SettingsOptionsStyles.divider}
                    styles={(theme) => ({
                      color: theme.theme.siteVariables
                        ? theme.theme.siteVariables.settings?.divider
                        : "",
                    })}
                  />
                  <SettingsOption
                    optionTitle={t("Settings.notifications.Status_change")}
                    optionCheck={isStatusChangeNotificationOn}
                    onChangeType={"status"}
                    optionLabel={t(
                      "Settings.accessibility.notifications.statusChange"
                    )}
                    setSettingsOnClick={setSettingsOnClick}
                  />
                </SecurityComponent>
                {
                  <>
                    <Flex
                      className="sectionTitle"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <Text weight="bold">{t("Settings.titles.Features")}</Text>
                    </Flex>
                    <Box
                      className={SettingsOptionsStyles.divider}
                      styles={(theme) => ({
                        color: theme.theme.siteVariables
                          ? theme.theme.siteVariables.settings?.divider
                          : "",
                      })}
                    />
                  </>
                }
                <ProviderConsumer
                  render={() => renderShareMyRideGlobalSettings()}
                />
                <ProviderConsumer
                  render={() => renderEnableAIChatBotSetting()}
                />

                {enableCytricClassicSupportLink?.isActive && (
                  <div>
                    <Flex
                      className="sectionTitle"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <Text weight="bold">{t("Settings.titles.support")}</Text>
                    </Flex>
                    <Box
                      className={SettingsOptionsStyles.divider}
                      styles={(theme) => ({
                        color: theme.theme.siteVariables
                          ? theme.theme.siteVariables.settings?.divider
                          : "",
                      })}
                    />
                    <Flex
                      className="sectionItem"
                      column
                      style={{ backgroundColor: "transparent" }}
                    >
                      <ProviderConsumer render={() => renderSupportPage()} />
                    </Flex>
                  </div>
                )}
              </Flex>
              <Flex vAlign="end" column className="footer">
                <Flex column>{learningHubLink}</Flex>
                <Flex column>
                  <Feature
                    activeComponent={releaseVersionNumber}
                    name={FeatureToggleDefinition.releaseVersionNumber.id}
                  />
                  <Text content={`${t("Settings.Log_Id")} ${userObjectId}`} />
                </Flex>
              </Flex>
            </div>
          </>
        )}
      </>
    );
  }

  return null;
}

export default SettingsOptions;
