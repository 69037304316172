import {
  combineReducers,
  // compose,
  configureStore,
} from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";

import logger from "redux-logger";
import AppSettingsReducer from "./app-settings/AppSettingsSlice";
import authenticationReducer from "./authentication/AuthenticationSlice";
import BreadcrumbReducer from "./breadcrumb/breadcrumb-slice";
import CoachmarksSlice from "./coachmarks/CoachmarksSlice";
import CloseCollaboratorsSlice from "./collaborators/close-collaborators/CloseCollaboratorsSlice";
import CloseCollaboratorsNotificationSlice from "./collaborators/close-collaborators/close-collaborators-notification/CloseCollaboratorsNotificationSlice";
import ReverseCollaboratorsSlice from "./collaborators/reverse-collaborators/ReverseCollaboratorsSlice";
import CytricContextReducer from "./cytric-context/CytricContextSlice";
import CytricExpenseContextReducer from "./cytric-context/CytricExpenseContextSlice";
import ExpenseDashboardReducer from "./expense-dashboard/ExpenseDashboardSlice";
import ExpenseStatementsToSubmitReducer from "./expense-statements-to-submit/ExpenseStatementsToSubmitSlice";
import FeatureTogglesSlice from "./feature-toggles/FeatureTogglesSlice";
import flowsPermissionsSlice from "./flows/FlowsPermissionsSlice";
import NotificationSettingsSlice from "./notification-settings/NotificationSettingsSlice";
import RedirectionReducer from "./redirection/RedirectionSlice";
import SegmentSelectedReducer from "./segment-selected/SegmentSelectedSlice";
import ShareTransferEnableAllReducer from "./shareTransferEnableAll/shareTransferEnableAllSlice";
import TodosReducer from "./todos/todos-slice";
import TravelTripIdSlice from "./travel-trip-id/TravelTripIdSlice";
import TripDetailsV1Slice from "./trip-details-v1/TripDetailsV1Slice";
import TripsV1Reducer from "./trips-v1/TripsV1Slice";
import TripsReducer from "./trips/TripsSlice";
import TripActionsReducer from "./upcoming-trips-dashboard/trip-add-booking-actions-slice";
import TripDetailReducer from "./upcoming-trips-dashboard/trip-detail-slice";
import TripSummaryReducer from "./upcoming-trips-dashboard/trips-summary-slice";

const myReducer = {
  segmentSelected: SegmentSelectedReducer,
  trips: TripsReducer,
  tripsV1: TripsV1Reducer,
  redirection: RedirectionReducer,
  authentication: authenticationReducer,
  cytricContext: CytricContextReducer,
  cytricExpenseContext: CytricExpenseContextReducer,
  tripDetail: TripDetailReducer,
  tripDetailV1: TripDetailsV1Slice,
  tripsSummary: TripSummaryReducer,
  notificationSettings: NotificationSettingsSlice,
  closeCollaborators: CloseCollaboratorsSlice,
  todos: TodosReducer,
  appSettings: AppSettingsReducer,
  travelTripId: TravelTripIdSlice,
  featureToggles: FeatureTogglesSlice,
  coachmarks: CoachmarksSlice,
  expenseStatementsToSubmit: ExpenseStatementsToSubmitReducer,
  closeCollaboratorsNotification: CloseCollaboratorsNotificationSlice,
  reverseCollaborators: ReverseCollaboratorsSlice,
  breadcrumb: BreadcrumbReducer,
  expenseDashbard: ExpenseDashboardReducer,
  shareTransferEnableAll: ShareTransferEnableAllReducer,
  flowPermissions: flowsPermissionsSlice,
  tripAddBooking: TripActionsReducer,
};
const createReducer = (injectedReducers = {}) =>
  combineReducers({
    ...myReducer,
    ...injectedReducers,
  });

const middlewares: any[] = [logger];

const store = configureStore({
  reducer: myReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          "payload.config",
          "payload.headers",
          "payload.request",
        ],
      },
    })
      // prepend and concat calls can be chained
      .concat(middlewares),
  devTools: {
    shouldHotReload: false,
  },
  enhancers: (current) =>
    current.concat(
      createInjectorsEnhancer({
        createReducer,
        runSaga: () => null,
      })
    ),
});

export default store;
