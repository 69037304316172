/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { CloseIcon, Dialog, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TripHelper from "../../helpers/TripHelper";
import FlowsService from "../../services/flows/flows.service";
import { coachmarksActions } from "../../store/coachmarks/CoachmarksSlice";
import { selectLastUrlDateTimeout } from "../../store/cytric-context/CytricContextSelector";
import { flowsPermissionsActions } from "../../store/flows/FlowsPermissionsSlice";
import { shareTransferEnableAllActions } from "../../store/shareTransferEnableAll/shareTransferEnableAllSlice";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import { selectedTravelTripId } from "../../store/travel-trip-id/TravelTripIdSelector";
import {
  resetTravelTripId,
  updateTravelTripId,
} from "../../store/travel-trip-id/TravelTripIdSlice";
import { fetchUserTripsV1 } from "../../store/trips-v1/TripsV1Slice";
import { tripsSummaryActions } from "../../store/upcoming-trips-dashboard/trips-summary-slice";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";
import returnKeepSession, {
  getKeepSessionValue,
} from "../../utils/KeepSessionFunctions";
import Coachmarks from "../coachmarks/Coachmarks";
import { COACHMARK_FLOW } from "../coachmarks/data/constants";
import { decodeIfSharedFromCytric } from "../share-from-classic/functions/utils";
import CheckContextForTripId from "./CheckContextForTripId";
import Error from "./error/Error";
import { SegmentType } from "./UpcomingTrips/CardBookTripButtons/model/Permissions";
import flowsPermissionFixer from "./UpcomingTrips/CardBookTripButtons/PunchOutLogic";
import CardSkeleton from "./UpcomingTrips/Skeleton/CardSkeleton";
import UpcomingTrips from "./UpcomingTrips/UpcomingTrips";
import BookingCytricRedirection from "./utils/BookingCytricRedirection";
import HandleCytricSession from "./utils/HandleCytricSession";
import { extractTripId } from "./utils/trip-utils";

export default function UpcomingTripsDashboard() {
  const { t } = useTranslation();
  const [content, setContent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionHandled, setSessionHandled] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { status, errorCode, trips } = useAppSelector((state) => state.tripsV1);
  const tourDidRun = useAppSelector((state) => state.coachmarks.tourDidRun);
  const travelTripId: string = useAppSelector((state) =>
    selectedTravelTripId(state)
  );
  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPUpcomingTrips.id
  );

  const featureHideSharedMessageForBotBookings = CheckFeatureToggle(
    FeatureToggleDefinition.hideSharedTripMessageForEasyBotBookButton.id
  );

  // cytric Tab information
  const cytricSessionDateTimeout: number = useAppSelector((state) =>
    selectLastUrlDateTimeout(state)
  );

  const isCytricSessionExpired: boolean = useAppSelector(
    (state) => selectLastUrlDateTimeout(state) < new Date().getTime()
  );
  // cytric Tab information

  const [isTripCanceledDialogOpen, setTripCanceledDialogOpen] = useState(false);
  const [isCanceledTrip, setIsCanceledTrip] = useState(false);

  const closeTripCanceledDialog = () => {
    setTripCanceledDialogOpen(false);
    setIsCanceledTrip(false);
  };

  const isTripCanceledPopUpEnabled = CheckFeatureToggle(
    FeatureToggleDefinition.isTripCanceledPopUpEnabled.id
  );

  const dialogTitle = t(
    "upcoming_trips_dashboard.upcomingTrips.popUp.tripCanceled"
  );
  const dialogText = t(
    "upcoming_trips_dashboard.upcomingTrips.popUp.tripDetailsNotAvailable"
  );

  const showComponent = async () => {
    let perms: SegmentType[] = [];
    if (!isTRIPPUser) {
      const response = await FlowsService.getFlows();
      if (response) {
        const flowPermissions = flowsPermissionFixer(response);
        dispatch(flowsPermissionsActions.setPermissions(flowPermissions));
        perms = flowPermissions;
      } else {
        perms = [];
      }
    }

    setContent(<UpcomingTrips permissions={perms} />);
  };

  const handleOpenSidebar = (subEntityId: any) => {
    if (subEntityId && subEntityId === "openSidebar") {
      localStorage.removeItem("KeepSessionCache");
      dispatch(tripsSummaryActions.setIsSidebarHidden(false));
      const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel`;
      app.openLink(deepLink);
    }
  };

  const isFromNotificationViewTripDetails = (subEntityId: any) =>
    /viewTripDetails/.test(subEntityId);

  const isFirstTimeCoachmarkSubEntity = (subEntityId: any) =>
    // !tourDidRun check prevents endless navigation loop
    // when tripId belongs to rejected/cancelled trip (404 from Trips API)
    (/NotifyCloseCollaborators/.test(subEntityId) ||
      /shareTransferEnableAll/.test(subEntityId)) &&
    !tourDidRun;

  const handleCoachmarkFlow = (subEntityId: any, tripId: string) => {
    let strategy = "";
    let trackEventName = "";
    if (/NotifyCloseCollaborators/.test(subEntityId)) {
      strategy = "notifyCloseCollaboratorsFlow";
      trackEventName = "travel-notification-notifyCloseCollaborators";
    } else if (/shareTransferEnableAll/.test(subEntityId)) {
      strategy = "shareTransferEnableAllFlow";
      trackEventName = "travel-notification-shareTransfer";
      // Enable share transfer for all segments in this trip after selected by user
      dispatch(shareTransferEnableAllActions.setEnableAll(true));
    }
    if (strategy !== "") {
      dispatch(coachmarksActions.setFlow(strategy));
      dispatch(coachmarksActions.setIsJoyrideActive(true));
      dispatch(coachmarksActions.setTourDidRun(true));

      telemetryService.trackEvent({ name: trackEventName }, { tripId });
    }
  };

  // Redirect to Cytric inside travel tab
  const handleCytricRedirection = (subEntityId: string) => {
    if (!isTRIPPUser) {
      localStorage.removeItem("KeepSessionCache");
      localStorage.setItem("tripDetailTab", "");
      const isGptBookButton =
        featureHideSharedMessageForBotBookings &&
        TripHelper.isRedirectionFromEasyBotBookButton(
          JSON.parse(subEntityId)?.buttonType
        );
      BookingCytricRedirection(
        subEntityId,
        history,
        t,
        dispatch,
        isGptBookButton
      );
    } else {
      const tabName = "travel";
      const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/${tabName}}?context=${subEntityId}`;
      app.openLink(deepLink);
    }
  };

  const isRedirectionFromJoinCard = (subEntityId: string) => {
    if (
      subEntityId.includes("segmentType") &&
      !subEntityId.includes("NotifyCloseCollaborators")
    ) {
      return true;
    }
    return false;
  };

  // handleDataTracking : Send data tracking event when user click on the "View trip details button" in Notification card
  const handleDataTracking = (subEntityId: any, tripId: any) => {
    if (isFromNotificationViewTripDetails(subEntityId)) {
      telemetryService.trackEvent(
        { name: "travel-notification-viewTrip" },
        { tripId }
      );
    }
  };

  const getSubEntityId = (subPageId: string | undefined) => {
    const isTripIdOnly = subPageId != null ? /^\d+$/.test(subPageId) : false;
    return isTripIdOnly ? subPageId : decodeIfSharedFromCytric(subPageId);
  };

  const tripRedirection = (subEntityId: any) => {
    const tripId = extractTripId(subEntityId);
    if (isFirstTimeCoachmarkSubEntity(subEntityId)) {
      localStorage.removeItem("KeepSessionCache");
      dispatch(updateTravelTripId({ tripId }));
      handleCoachmarkFlow(subEntityId, tripId);
      history.push(`/trip:${tripId}`);
    } else {
      // NOTE: This covers situation when user clicks the View Trip button from the notification.
      // ?context={"subEntityId": "1234567890", "source": "viewTripDetails"}
      handleDataTracking(subEntityId, tripId);
      dispatch(updateTravelTripId({ tripId }));
      history.push(`/trip:${tripId}`);
    }
  };

  const setError = () => {
    if (errorCode === 429) {
      setContent(<Error type="many" />);
    } else if (errorCode) {
      setContent(<Error type="error" />);
    } else {
      setContent(<Error type="error" />);
    }
  };

  const getTrips = () => {
    dispatch(fetchUserTripsV1());

    setIsLoading(true);
  };

  const manageElse = (tabSaved: string) => {
    app.getContext().then((context: app.Context) => {
      if (context) {
        const subEntityId = context?.page?.subPageId;
        if (
          subEntityId !== undefined &&
          subEntityId !== '{"BoundTo":"DeleteNotification"}' &&
          subEntityId !== "takeATour"
        ) {
          const tabToredirect = CheckContextForTripId(tabSaved, subEntityId);
          history.push(tabToredirect);
        } else {
          getTrips();
        }
      } else {
        history.push(tabSaved);
      }
    });
  };

  const manageKeepSession = (tabSaved: string) => {
    const keepSessionObject = returnKeepSession();
    if (keepSessionObject) {
      const lastUpdate = keepSessionObject.expiration;
      if (lastUpdate !== "" && lastUpdate !== null) {
        const now = new Date();
        if (now.getTime() > JSON.parse(lastUpdate)) {
          localStorage.removeItem("KeepSessionCache");
          window.location.reload();
        } else {
          manageElse(tabSaved);
        }
      }
    }
  };

  const handleSession = useCallback(() => {
    if (sessionHandled) {
      return;
    }
    setSessionHandled(true);

    const keepSessionObject = returnKeepSession();
    const keepCytricSession = HandleCytricSession(
      keepSessionObject,
      isCytricSessionExpired,
      cytricSessionDateTimeout,
      dispatch,
      history,
      t
    );
    if (keepSessionObject) {
      if (!keepCytricSession) {
        let tabSaved = keepSessionObject.page;
        if (tabSaved === "/trip:") {
          tabSaved += getKeepSessionValue(keepSessionObject, "tripId");
        }
        if (tabSaved) {
          manageKeepSession(tabSaved);
        } else {
          getTrips();
        }
      } else {
        getTrips();
      }
    } else {
      getTrips();
    }
  }, [isCytricSessionExpired, cytricSessionDateTimeout, dispatch, history, t]);
  const checkSubEntityId = useCallback(
    (subEntityId: any) => {
      if (subEntityId && subEntityId === '{"BoundTo":"DeleteNotification"}') {
        localStorage.removeItem("KeepSessionCache");
        dispatch(resetTravelTripId());
      } else if (subEntityId === "takeATour" && !tourDidRun) {
        localStorage.removeItem("KeepSessionCache");
        dispatch(coachmarksActions.setFlow(COACHMARK_FLOW.TAKE_A_TOUR));
        dispatch(coachmarksActions.setIsJoyrideActive(true));
        dispatch(coachmarksActions.setTourDidRun(true));
        history.push("/travel");
      } else if (
        isRedirectionFromJoinCard(subEntityId) ||
        subEntityId.includes("bookFromTripLogistics")
      ) {
        handleCytricRedirection(subEntityId);
      } else if (
        subEntityId &&
        travelTripId !== subEntityId &&
        subEntityId !== "takeATour" &&
        subEntityId !== "canceledTrip"
      ) {
        tripRedirection(subEntityId);
      } else {
        handleSession();
      }

      if (subEntityId && subEntityId === "canceledTrip") {
        setIsCanceledTrip(true);
      }
    },
    [tourDidRun, travelTripId, dispatch, history]
  );

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        app
          .getContext()
          .then((context: app.Context) => {
            if (context) {
              const subPageId = context?.page.subPageId;
              const subEntityId =
                subPageId === "canceledTrip"
                  ? "canceledTrip"
                  : getSubEntityId(subPageId);
              handleOpenSidebar(subEntityId);
              checkSubEntityId(subEntityId);
            }
          })
          .catch(() => {
            handleSession();
          });
      })
      .catch(() => {
        handleSession();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, travelTripId, tourDidRun]);

  useEffect(() => {
    app.initialize();

    const updateAppIconYCoordinate = () => {
      app.getContext().then((context: app.Context) => {
        if (context) {
          const appIconPosition = context.app?.iconPositionVertical;
          dispatch(coachmarksActions.setAppIconYCoordinate(appIconPosition));
        }
      });
    };

    updateAppIconYCoordinate();

    const handleWindowResize = () => {
      setTimeout(() => {
        updateAppIconYCoordinate();
      }, 1000);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === "loading") {
      setIsLoading(true);
    } else if (status === "error") {
      setError();
      setIsLoading(false);
    } else if (status === "success") {
      dispatch(tripsSummaryActions.setTrips(trips));
      showComponent();
      setIsLoading(false);
    }
  }, [status, errorCode, trips]);

  useEffect(() => {
    if (isCanceledTrip && status === "success" && !isTripCanceledDialogOpen) {
      setTimeout(() => {
        setTripCanceledDialogOpen(true);
      }, 1000); // 1 sec timeout to make sure that trips are shown before the popup
    }
  }, [isCanceledTrip, status]);

  if (isLoading) {
    return (
      <>
        <CardSkeleton />;
      </>
    );
  }

  return (
    <>
      {content}
      <Coachmarks />
      {isTripCanceledPopUpEnabled && isTripCanceledDialogOpen && (
        <Dialog
          closeOnOutsideClick
          onCancel={closeTripCanceledDialog}
          header={dialogTitle}
          headerAction={{
            icon: <CloseIcon />,
            title: t(
              "upcoming_trips_dashboard.upcomingTrips.popUp.closeDialog"
            ),
            className: "close__btn",
            onClick: closeTripCanceledDialog,
          }}
          content={<Text>{dialogText}</Text>}
          backdrop
          open={isTripCanceledDialogOpen}
          confirmButton={t(
            "upcoming_trips_dashboard.upcomingTrips.popUp.close"
          )}
          onConfirm={closeTripCanceledDialog}
          aria-labelledby={dialogTitle}
          aria-describedby={dialogText}
          className="dialog"
          data-testid="trip-canceled-dialog"
        />
      )}
    </>
  );
}
