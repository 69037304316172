/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
import DateUtils from "../../../utils/date.utils";
import { IHotelSegmentV1 } from "../../upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/Hotel/Hotel.model";
import getExpiration from "../components/share-my-ride-container/functions/getExpiration";
import { SameHotelArgs } from "../models/ShareMyRideContext.model";
import { getFormattedGeoCode } from "./shareMyRideUtils";

export function getSegmentDetails(detail: any) {
  return Object.values(detail).flatMap((innerArray) =>
    (innerArray as any[]).flat()
  );
}

function setSharedInfoObject(
  sharedInfoAllSegments: any,
  arrivalObject: any,
  departureObject: any
) {
  sharedInfoAllSegments.push(departureObject);
  sharedInfoAllSegments.push(arrivalObject);
  return sharedInfoAllSegments;
}

function getFlightServiceProviderInfo(flightDetails: any) {
  const airlineNumbersObj = flightDetails.reduce(
    (
      acc: { [key: string]: string[] },
      flight: { serviceProvider: string; number: string }
    ) => {
      acc[flight.serviceProvider] = acc[flight.serviceProvider] || [];
      acc[flight.serviceProvider].push(flight.number);
      return acc;
    },
    {}
  );

  return Object.entries(airlineNumbersObj)
    .map(
      ([serviceProvider, numbers]: [string, any]) =>
        `${serviceProvider}, ${numbers.join(", ")}`
    )
    .join(", ");
}

function getRailServiceProviderInfo(railDetails: any) {
  return railDetails.basicDetails?.serviceProvidersWithNumbers
    .map(
      (info: { name: string; numbers: string[] }) =>
        `${info.name}, ${info.numbers.join(", ")}`
    )
    .join(", ");
}

function getCountryName(countryCode: string): string {
  if (!countryCode) {
    return "";
  }
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const countryName = regionNames.of(countryCode.toUpperCase());
  if (typeof countryName === undefined) {
    return "";
  }
  return countryName as string;
}

function getHotelArr(hotelDetails: any): any[] {
  return Array.isArray(hotelDetails) ? hotelDetails : [];
}

function updateHotelTempObj(
  hotelTempObj: any,
  hotel: any,
  dateType: string,
  locationType: string
) {
  if (Object.keys(hotelTempObj).length > 0) {
    const dateFromTempObj = new Date(hotelTempObj[dateType]);
    const currentDate = new Date(hotel[dateType]);
    if (
      (locationType === "DEPARTURE" && currentDate > dateFromTempObj) ||
      (locationType === "ARRIVAL" && currentDate < dateFromTempObj)
    ) {
      return hotel;
    }
  } else {
    return hotel;
  }
  return hotelTempObj;
}

function getHotelForSegment(sameHotelArgs: SameHotelArgs): any {
  const dateTime: string = DateUtils.getDateStringPart(
    sameHotelArgs.dateTimeToCheck
  );
  const { countryToCheck } = sameHotelArgs;

  let hotelTempObj: any = {};
  const hotelArr = getHotelArr(sameHotelArgs.hotelDetails);
  const ARRIVAL_CONST = "ARRIVAL";
  const DEPARTURE_CONST = "DEPARTURE";
  hotelArr.forEach((hotel: IHotelSegmentV1) => {
    const hotelCheckInDate = DateUtils.getDateStringPart(hotel?.checkInDate);
    const hotelCheckOutDate = DateUtils.getDateStringPart(hotel?.checkOutDate);
    const hotelCountry: string = hotel?.address.countryCode;
    if (
      sameHotelArgs.locationType === ARRIVAL_CONST &&
      hotelCheckInDate >= dateTime &&
      countryToCheck.toLowerCase() === hotelCountry.toLowerCase()
    ) {
      hotelTempObj = updateHotelTempObj(
        hotelTempObj,
        hotel,
        "checkInDate",
        ARRIVAL_CONST
      );
    } else if (
      sameHotelArgs.locationType === DEPARTURE_CONST &&
      hotelCheckOutDate <= dateTime &&
      countryToCheck.toLowerCase() === hotelCountry.toLowerCase()
    ) {
      hotelTempObj = updateHotelTempObj(
        hotelTempObj,
        hotel,
        "checkOutDate",
        DEPARTURE_CONST
      );
    }
  });
  return hotelTempObj;
}

export default function filterAllSegments(
  trip: any,
  railDetails: any,
  flightDetails: any,
  hotelDetails: any
) {
  let sharedInfoAllSegments: any[] = [];
  if (
    !railDetails["Invalid Date"] ||
    (trip.products && trip.products[0].hasRail)
  ) {
    const railsArray = getSegmentDetails(railDetails);

    railsArray.forEach((rail: any) => {
      const railObjectArrival = {
        arrivalStation: rail.basicDetails.arrival,
        departureStation: rail.basicDetails.departure,
        date: rail.basicDetails.arrivalDateTime,
        serviceProvider:
          rail.extendedDetails[rail.extendedDetails.length - 1].serviceProvider,
        vehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[
            rail.basicDetails.serviceProvidersWithNumbers.length - 1
          ].numbers[
            rail.basicDetails.serviceProvidersWithNumbers[0].numbers.length - 1
          ],
        geoCode: getFormattedGeoCode(
          rail.extendedDetails[
            rail.extendedDetails.length - 1
          ].arrival.geoCode?.latitude?.toString(),
          rail.extendedDetails[
            rail.extendedDetails.length - 1
          ].arrival.geoCode?.longitude?.toString()
        ),
        prevServiceProvider: rail.extendedDetails[0].serviceProvider,
        prevVehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[0].numbers[0],
        type: "RAIL",
        locationType: "ARRIVAL",
        arrivalDateTime: DateUtils.getUTCTime(
          rail.basicDetails.arrivalDateTime
        ),
        departureDateTime: DateUtils.getUTCTime(
          rail.basicDetails.departureDateTime
        ),
        serviceProviderInfo: getRailServiceProviderInfo(rail),
        hotelForSegment: getHotelForSegment({
          locationType: "ARRIVAL",
          dateTimeToCheck: rail.basicDetails.arrivalDateTime,
          countryToCheck: getCountryName(
            rail.extendedDetails[rail.extendedDetails.length - 1].arrival
              ?.address?.countryCode
          ),
          hotelDetails,
        }),
      };

      const railObjectDeparture = {
        arrivalStation: rail.basicDetails.arrival,
        departureStation: rail.basicDetails.departure,
        date: rail.basicDetails.departureDateTime,
        serviceProvider: rail.extendedDetails[0].serviceProvider,
        vehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[0].numbers[
            rail.basicDetails.serviceProvidersWithNumbers[0].numbers.length - 1
          ],
        geoCode: getFormattedGeoCode(
          rail.extendedDetails[0].departure.geoCode?.latitude?.toString(),
          rail.extendedDetails[0].departure.geoCode?.longitude?.toString()
        ),
        prevServiceProvider: rail.extendedDetails[0].serviceProvider,
        prevVehicleNumber:
          rail.basicDetails.serviceProvidersWithNumbers[0].numbers[0],
        type: "RAIL",
        locationType: "DEPARTURE",
        arrivalDateTime: DateUtils.getUTCTime(
          rail.basicDetails.arrivalDateTime
        ),
        departureDateTime: DateUtils.getUTCTime(
          rail.basicDetails.departureDateTime
        ),
        serviceProviderInfo: getRailServiceProviderInfo(rail),
        hotelForSegment: getHotelForSegment({
          locationType: "DEPARTURE",
          dateTimeToCheck: rail.basicDetails.departureDateTime,
          countryToCheck: getCountryName(
            rail.extendedDetails[0].departure?.address?.countryCode
          ),
          hotelDetails,
        }),
      };

      const notExpired = getExpiration(rail.basicDetails.arrivalDateTime);
      if (notExpired) {
        sharedInfoAllSegments = setSharedInfoObject(
          sharedInfoAllSegments,
          railObjectArrival,
          railObjectDeparture
        );
      }
    });
  }

  if (
    !flightDetails["Invalid Date"] ||
    (trip.products && trip.products[0].hasAir)
  ) {
    const airArray = getSegmentDetails(flightDetails);
    airArray.forEach((flight: any) => {
      const firstFlight = flight?.extendedDetails[0];
      const lastFlight =
        flight?.extendedDetails[flight.extendedDetails.length - 1];

      const locationTypeArrival = "ARRIVAL";
      const flightObjectArrival = {
        arrivalAirportName: lastFlight?.arrival.airportName,
        airportCode: lastFlight?.arrival.iataCode,
        date: lastFlight?.arrival.dateTime,
        flightReference: `${lastFlight?.carrierCode}${lastFlight?.number}`,
        prevFlightReference: `${firstFlight?.carrierCode}${firstFlight?.number}`,
        terminal: lastFlight?.arrival.terminal,
        type: "AIR",
        locationType: locationTypeArrival,
        arrivalIataCode: lastFlight.arrival.iataCode,
        departureIataCode: firstFlight.departure.iataCode,
        departureDateTime: DateUtils.getUTCTime(firstFlight.departure.dateTime),
        arrivalDateTime: DateUtils.getUTCTime(lastFlight.arrival.dateTime),
        serviceProviderInfo: getFlightServiceProviderInfo(
          flight?.extendedDetails
        ),
        hotelForSegment: getHotelForSegment({
          locationType: locationTypeArrival,
          dateTimeToCheck: lastFlight?.arrival.dateTime,
          countryToCheck: lastFlight?.arrival.countryName,
          hotelDetails,
        }),
      };

      const locationTypeDeparture = "DEPARTURE";
      const flightObjectDeparture = {
        departureAirportName: firstFlight?.departure.airportName,
        airportCode: firstFlight?.departure.iataCode,
        date: firstFlight?.departure.dateTime,
        flightReference: `${firstFlight?.carrierCode}${firstFlight?.number}`,
        prevFlightReference: `${firstFlight?.carrierCode}${firstFlight?.number}`,
        terminal: firstFlight?.departure.terminal,
        type: "AIR",
        locationType: locationTypeDeparture,
        arrivalIataCode: lastFlight.arrival.iataCode,
        departureIataCode: firstFlight.departure.iataCode,
        departureDateTime: DateUtils.getUTCTime(firstFlight.departure.dateTime),
        arrivalDateTime: DateUtils.getUTCTime(lastFlight.arrival.dateTime),
        serviceProviderInfo: getFlightServiceProviderInfo(
          flight?.extendedDetails
        ),
        hotelForSegment: getHotelForSegment({
          locationType: locationTypeDeparture,
          dateTimeToCheck: firstFlight?.departure.dateTime,
          countryToCheck: firstFlight?.departure.countryName,
          hotelDetails,
        }),
      };
      const notExpired = getExpiration(flight.basicDetails.arrivalDateTime);
      if (notExpired) {
        sharedInfoAllSegments = setSharedInfoObject(
          sharedInfoAllSegments,
          flightObjectArrival,
          flightObjectDeparture
        );
      }
    });
  }

  const sortedSegments = [...sharedInfoAllSegments].sort((a: any, b: any) =>
    a.date > b.date ? 1 : -1
  );

  return sortedSegments;
}
