import {
  Airplane20Regular,
  Airplane24Regular,
  Bed20Regular,
  Bed24Regular,
  VehicleSubway20Regular,
  VehicleSubway24Regular,
} from "@fluentui/react-icons";
import { ComponentStyleFunctionParam, Flex } from "@fluentui/react-northstar";
import Responsive from "../../../common/components/responsive/Responsive";
import i18next from "../../../i18n/i18n";
import Styles from "../ShareMyRide.module.scss";
import { Flight } from "../models/ShareMyRide.model";

const { t } = i18next;

const getSameSegmentIcons = ({
  segmentData,
  segmentType,
}: {
  segmentData: Flight;
  segmentType: string;
}) => {
  const { sameFlight, sameHotel } = segmentData;
  const ICON_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.segmentIcons?.icons
      : "",
  });

  const retrieveIcons = (size: string) => {
    const buffer = [];
    if (segmentType === "AIR" && sameFlight) {
      buffer.push(
        <Flex
          styles={ICON_STYLE}
          key="Flight1"
          title={t("shareMyRide.sameFlightTag")}
          role="img"
          aria-hidden
        >
          {size === "tablet" ? <Airplane24Regular /> : <Airplane20Regular />}
        </Flex>
      );
    }

    if (segmentType === "RAIL" && sameFlight) {
      buffer.push(
        <Flex
          styles={ICON_STYLE}
          key="Rail2"
          title={t("shareMyRide.sameTrainTag")}
          role="img"
          aria-hidden
        >
          {size === "tablet" ? (
            <VehicleSubway24Regular />
          ) : (
            <VehicleSubway20Regular />
          )}
        </Flex>
      );
    }
    if (sameHotel) {
      buffer.push(
        <Flex
          styles={ICON_STYLE}
          className={
            segmentType === "RAIL" && size === "desktop"
              ? Styles.segments__rail_w_hotel
              : ""
          }
          key="Hotel3"
          title={t("shareMyRide.sameHotelTag")}
          role="img"
          aria-hidden
        >
          {size === "tablet" ? <Bed24Regular /> : <Bed20Regular />}
        </Flex>
      );
    }

    return buffer;
  };

  const iconsForMobile = (
    <Responsive forMaxSize="tablet">{retrieveIcons("tablet")}</Responsive>
  );
  const iconsForDesktop = (
    <Responsive forMinSize="desktopSmall">
      {retrieveIcons("desktop")}
    </Responsive>
  );
  return (
    <div className={Styles.segments__icons} data-testid="same-segment-icons">
      {iconsForMobile}
      {iconsForDesktop}
    </div>
  );
};

export default getSameSegmentIcons;
