/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-named-as-default */
// Dependencies
import {
  ApiDataModel,
  EasyContext,
  IEasyContextModel,
  ITelemetryProviderProps,
  Security,
  SecurityType,
  setHttpData,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  EditHeaderForm,
  EventConfiguration,
  EventDialog,
  EventDialogDeleteTrip,
  ManageOrganizers,
  NewEventShareTrip,
  TextEditor,
} from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-events";
import {
  Loader,
  Provider as UIProvider,
  mergeThemes,
  teamsTheme,
} from "@fluentui/react-northstar";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { app } from "@microsoft/teams-js";
import { FeatureToggles } from "@paralleldrive/react-feature-toggles";
import { useEffect, useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  RouteProps,
  HashRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import GenericInfo from "../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
} from "../common/components/generic-info-component/GenericInfo.model";
import TaskModuleResponseError from "../common/components/task-module-response/TaskModuleResponseError";
import TaskModuleResponseSuccess from "../common/components/task-module-response/TaskModuleResponseSuccess";
import UnauthorizedComponent from "../common/components/unauthorized-component/UnauthorizedComponent";
import { IError } from "../common/models/Error";
import FeatureToggleData from "../common/models/FeatureToggleData";
import SecurityComponent from "../common/security";
import useTeamsFx from "../hooks/useTeamsFx";
import "../i18n/i18n";
import store from "../store/Store";
import { useAppDispatch, useAppSelector } from "../store/StoreHooks";
import {
  contextSelector,
  httpDataSelector,
  isHttpDataFilledSelector,
} from "../store/app-settings/AppSettingsSelector";
import { appSettingsActions } from "../store/app-settings/AppSettingsSlice";
import {
  isLoggedInSelector,
  userObjectIdSelector,
} from "../store/authentication/AuthenticationSelector";
import {
  resetAuthentication,
  updateUserObjectId,
} from "../store/authentication/AuthenticationSlice";
import { resetCytricContext } from "../store/cytric-context/CytricContextSlice";
import { getFeatureToggles } from "../store/feature-toggles/FeatureTogglesAction";
import {
  selectActiveFeatureToggles,
  selectFeatureToggles,
  selectFeatureTogglesIsCompleted,
} from "../store/feature-toggles/FeatureTogglesSelector";
import { FeatureToggleDefinition } from "../utils/constants";
import HostSettings from "../utils/host.settings";
import "./App.scss";
import ExpenseDashboardSecured from "./expense-dashboard/ExpenseDashboardSecured";
import JoinATripTaskModule from "./join-a-trip/JoinATripTaskModule";
import MatchMyTripTaskModule from "./match-my-trip/MatchMyTripTaskModule";
import PersonalTab from "./personal-tab/PersonalTab";
import ShareFromClassicTaskModule from "./share-from-classic/ShareFromClassicTaskModule";
import UpcomingTripsDashboard from "./upcoming-trips-dashboard";
import UpcomingTripDetail from "./upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetail";
import useTheme from "./upcoming-trips-dashboard/useTheme";
// Components
import TelemetryProvider from "../common/components/telemetry/TelemetryProvider";
import HttpData from "../common/models/HttpData";

import CloseCollaboratorsEditorDialog from "./collaborators/close-collaborators/close-collaborators-editor-dialog/CloseCollaboratorsEditorDialog";
import EventAttendees from "./event-attendees/EventAttendees";
import OpenChatTaskModule from "./open-chat/OpenChatTaskModule";
import CytricClassic from "./personal-tab/CytricClassic";
import SettingsWrapper from "./settings/SettingsWrapper";
import ShareJoinContainer from "./share-join/ShareJoinContainer";
import TripApproval from "./trip-approval/TripApproval";

// Manage Feature Toggle
function RenderFeatureToggle() {
  const features = useAppSelector((state) => selectActiveFeatureToggles(state));
  const featureToggles = useAppSelector((state) => selectFeatureToggles(state));
  const context = useAppSelector((state) => contextSelector(state));
  const featuresCompleted = useAppSelector((state) =>
    selectFeatureTogglesIsCompleted(state)
  );
  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );

  const [easyContext, setEasyContext] = useState<IEasyContextModel>(
    {} as IEasyContextModel
  );
  const [hasTARole, setHasTARole] = useState(false);

  const checkIfTARole = () =>
    Security.hasAnyRole([
      "TRAVEL_ARRANGER-TravelCoordinatorRestricted",
      "TRAVEL_ARRANGER-TravelCoordinatorNoTad",
      "TRAVEL_ARRANGER-TravelCoordinator",
      "TRAVEL_ARRANGER-TravelCoordinatorAll",
      "TRAVEL_ARRANGER-TravelCoordinatorEdit",
    ]);

  useEffect(() => {
    checkIfTARole().then((isTA: boolean) => {
      setHasTARole(isTA);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {
      hostSettings: HostSettings,
      featureToggles: FeatureToggleDefinition,
      data: { featureToggles },
    } as IEasyContextModel;
    setEasyContext(data);
  }, [featureToggles]);

  useEffect(() => {
    if (
      context &&
      Object.keys(context).length > 0 &&
      (!HostSettings.isCytricLoginEnabled || isLoggedIn)
    ) {
      const data: FeatureToggleData = {
        tenantId: context?.user?.tenant?.id || "",
      };
      store.dispatch(getFeatureToggles(data));
    }
  }, [isLoggedIn, context]);

  const MessagingComponent = ShareJoinContainer;

  return !featuresCompleted ? (
    <Loader style={{ margin: 100 }} />
  ) : (
    <FeatureToggles features={features}>
      <EasyContext.Provider value={easyContext}>
        <Route exact path="/">
          <Redirect to="/travel" />
        </Route>
        <Route exact path="/travel" render={() => <UpcomingTripsDashboard />} />
        <Route exact path="/trip:tripId" component={UpcomingTripDetail} />
        <Route
          exact
          path="/travel:canceledTrip"
          component={UpcomingTripsDashboard}
        />
        <Route
          exact
          path="/cytricweb"
          render={() => <PersonalTab hasTARole={hasTARole} />}
        />
        <Route exact path="/classic" component={CytricClassic} />
        <Route
          exact
          path="/share-flight-success"
          component={TaskModuleResponseSuccess}
        />
        <Route
          exact
          path="/share-flight-error"
          component={TaskModuleResponseError}
        />
        <Route
          exact
          path="/share-from-classic"
          component={ShareFromClassicTaskModule}
        />
        <Route exact path="/share-join" component={ShareJoinContainer} />
        <Route exact path="/event-attendees" component={EventAttendees} />
        <Route
          exact
          path="/match-my-trip"
          render={(routeProps: RouteProps) => (
            <MatchMyTripTaskModule location={routeProps.location} />
          )}
        />
        <Route
          exact
          path="/share-join-ta"
          render={(routeProps: RouteProps) => (
            <JoinATripTaskModule location={routeProps.location} />
          )}
        />
        <Route
          exact
          path="/messaging-trip-list"
          component={MessagingComponent}
        />
        <Route exact path="/expenses" component={ExpenseDashboardSecured} />
        <Route exact path="/settings" component={SettingsWrapper} />
        <Route exact path="/config" component={EventConfiguration} />
        <Route exact path="/edit-event-details" component={EditHeaderForm} />
        <Route exact path="/manage-organizers" component={ManageOrganizers} />
        <Route exact path="/text-editor" component={TextEditor} />
        <Route
          exact
          path="/close-collaborators-editor"
          render={(routeProps: RouteProps) => (
            <CloseCollaboratorsEditorDialog location={routeProps.location} />
          )}
        />
        <Route exact path="/tab">
          {NewEventShareTrip()}
        </Route>
        <Route exact path="/FlightDialog" component={EventDialog} />
        <Route exact path="/DeleteTrip" component={EventDialogDeleteTrip} />
        <Route exact path="/open-chat" component={OpenChatTaskModule} />
        <Route
          exact
          path="/approval-trip"
          render={(routeProps: RouteProps) => (
            <TripApproval location={routeProps.location} />
          )}
        />
      </EasyContext.Provider>
    </FeatureToggles>
  );
}

// Manage Security
function RenderSecurity() {
  return (
    <SecurityComponent
      securityRole={SecurityType.activationTeams}
      component={UnauthorizedComponent()}
    >
      <RenderFeatureToggle />
    </SecurityComponent>
  );
}

export function ErrorFallback({ error }: FallbackProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const requestId = telemetryService.trackException({
    error,
    severityLevel: SeverityLevel.Error,
  });
  const errorInfoBoundary: IGenericInfo = {
    title: t("messages.ERR_Generic"),
    detail: `Error reference: ${requestId}`,
  };
  const action: IGenericInfoAction = {
    text: t("App.Try_again"),
    command: () => {
      history.go(0);
    },
  };
  return (
    <GenericInfo
      infoData={errorInfoBoundary}
      action={action}
      image={InfoImage.ERROR}
    />
  );
}

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme, inTeams, context, themeString } = useTeamsFx();
  const history = useHistory();
  const [errorTeams, setErrorTeams] = useState(false);
  const { i18n, t } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);
  const [customTheme] = useTheme(themeString);
  const dispatch = useAppDispatch();
  const userObjectId: string = useAppSelector((state) =>
    userObjectIdSelector(state)
  );
  const appSettingsHttpData: HttpData = useAppSelector((state) =>
    httpDataSelector(state)
  );
  const appSettingsHttpDataFilled: boolean = useAppSelector((state) =>
    isHttpDataFilledSelector(state)
  );

  const telemetryProviderProps: ITelemetryProviderProps = {
    history,
    instrumentationKey: HostSettings.getInstrumentationKey,
    enabled: HostSettings.isTelemetryEnabled,
  };

  const handleUserIdentityChange = (
    currentContext: app.Context,
    previousUserObjectId: string
  ) => {
    if (currentContext?.user?.id !== previousUserObjectId) {
      // reset all store data from local store linked to the previous user
      localStorage.clear();
      store.dispatch(resetAuthentication());
      store.dispatch(resetCytricContext());
      store.dispatch(
        updateUserObjectId({ userObjectId: currentContext?.user?.id || "" })
      );
    }
  };

  const setHtmlLanguage = (locale: string) => {
    let result = HostSettings.getDefaultLanguage;
    // check locale is allowed
    if (
      locale &&
      HostSettings.getAllowedTranslationLanguages.includes(
        locale.substring(0, 2)
      )
    ) {
      result = locale;
    }
    if (result && result.length >= 2) {
      result = result.substring(0, 2);
    }
    document.documentElement.lang = result;
  };

  useEffect(() => {
    if (context && appSettingsHttpData && appSettingsHttpDataFilled) {
      const data: ApiDataModel = {
        telemetryService,
        unauthorizedUrl: "unauthorized",
        keycloack: {
          scope: HostSettings.tokenScopes,
          isEnabled: HostSettings.isCytricLoginEnabled,
        },
        headers: {
          acceptLanguage: appSettingsHttpData.acceptLanguage,
          apiKey: appSettingsHttpData.apiKey,
          appKey: appSettingsHttpData.appKey,
          aid: appSettingsHttpData.aid,
          tid: appSettingsHttpData.tid,
          contentType: appSettingsHttpData.contentType,
        },
      };

      setHttpData(data);
      setIsInitialized(true);
      setHtmlLanguage(context.app.locale);
    }
  }, [context, appSettingsHttpData, appSettingsHttpDataFilled]);

  useEffect(() => {
    if (inTeams === false) {
      setErrorTeams(true);
      setIsInitialized(true);
    }
    if (context) {
      handleUserIdentityChange(context, userObjectId);
      telemetryService.setUserId(context?.user?.id);
      dispatch(appSettingsActions.setLocale(context));
      dispatch(appSettingsActions.setHttpData(context));
      dispatch(appSettingsActions.setContext(context));
      i18n.changeLanguage(context?.app?.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inTeams, i18n, context]);

  const errorInfo: IError = {
    status: 0,
    code: 0,
    title: t(`messages.ERR_Generic`),
    detail: t(`messages.ERR_Teams`),
  };

  const renderApp = () => {
    if (!isInitialized) {
      return <Loader style={{ margin: 100 }} />;
    }
    if (errorTeams) {
      return <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />;
    }

    return (
      <Switch>
        <Route exact path="/unauthorized" component={UnauthorizedComponent} />
        <Route render={() => <RenderSecurity />} />
      </Switch>
    );
  };

  return (
    <Router>
      <UIProvider
        styles={{ background: customTheme.styles.backgroundColor }}
        theme={mergeThemes(theme || teamsTheme, customTheme.theme)}
      >
        <div className={`easy-${themeString}`}>
          <TelemetryProvider {...telemetryProviderProps}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {renderApp()}
            </ErrorBoundary>
          </TelemetryProvider>
        </div>
      </UIProvider>
    </Router>
  );
}
