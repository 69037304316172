import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Open16Regular } from "@fluentui/react-icons";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import i18n from "../../../../../../../../i18n/i18n";
import { getInboundDateOfRailBoundGroup } from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors-rail";
import CheckFeatureToggle from "../../../../../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../../../../../utils/constants";
import { SegmentType } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";
import firstLetterUppercase from "../../../../../../utils/firstLetterUppercase";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import AriaStyle from "../../SegmentTabs.module.scss";
import HandleSegmentPermissions from "../Common/HandleSegmentPermissions";
import Accordion from "../components/Accordion/Accordion";
import { IBound } from "../components/Accordion/Accordion.model";
import NewProduct from "../components/NewProduct/NewProduct";
import Styles from "./Rail.module.scss";

interface ShowRailProps {
  containsRail: boolean;
  railDetails: any;
  pastTrip: boolean;
  railSegments: any;
  permissions: SegmentType;
  bookRail: () => Promise<void>;
  addRail: boolean;
  t: any;
}

function ShowRail({
  containsRail,
  railDetails,
  pastTrip,
  railSegments,
  permissions,
  bookRail,
  addRail,
  t,
}: ShowRailProps) {
  const isBlackListed = CheckFeatureToggle(
    FeatureToggleDefinition.tmsSettingsBookings.id
  );

  if (containsRail) {
    const content = Object.keys(railDetails).map((key: string, i: number) => {
      const longStartDate = key;
      const endDate = getInboundDateOfRailBoundGroup(railDetails?.[key]) || "";
      const titleScreenReader = endDate
        ? `${t("upcoming_trips_dashboard.tripcard.from")} ${longStartDate} ${t(
            "upcoming_trips_dashboard.tripcard.to"
          )} ${DateUtils.getDateFormat(
            endDate,
            i18n.language,
            DateFormat.DATE_LONG
          )} ${railDetails?.[key]?.[0].length} ${t("tripDetails.item/s")}`
        : `${longStartDate} ${railDetails?.[key]?.[0].length} ${t(
            "tripDetails.item/s"
          )}`;

      const indexDateSection = `${key}-${i}-date-section`;

      return (
        <div key={indexDateSection} role="grid" aria-busy="true">
          <span className={AriaStyle.visuallyHidden}>{titleScreenReader}</span>
          <Text
            aria-hidden
            className={Styles.date}
            as="div"
            styles={(theme) => ({
              color: theme.theme.siteVariables.bookingDetail?.date,
            })}
          >
            <Text data-testid="date_trip_details">
              {firstLetterUppercase(key)}
              {endDate
                ? ` - ${firstLetterUppercase(
                    DateUtils.getDateFormat(
                      endDate,
                      i18n.language,
                      DateFormat.DATE_SHORT
                    )
                  )}`
                : null}
            </Text>
          </Text>
          {railDetails[key].map((boundGroup: IBound[], index: number) => {
            const indexAccordion = `${key}-${index}-accordion`;
            return (
              <div
                role="row"
                key={indexAccordion}
                aria-label={`${t(
                  "upcoming_trips_dashboard.tripcard.icons.Train"
                )} ${i + 1} ${t("of")} ${Object.keys(railDetails).length} `}
              >
                <Accordion boundGroup={boundGroup} type="Rail" />
              </div>
            );
          })}
        </div>
      );
    });

    return (
      <>
        {content}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({
            productType: "train",
            numOfProducts: railSegments.length,
          })}
      </>
    );
  }

  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.train")}
      />
      {CheckFeatureEnabled() &&
        HandleSegmentPermissions(permissions, isBlackListed, addRail) && (
          <Button
            content={t(
              "upcoming_trips_dashboard.tripDetails.newProduct.buttons.train"
            )}
            onClick={() => bookRail()}
            flat
            icon={<Open16Regular data-testid="external-link-icon-train" />}
            aria-label={`${t(
              "upcoming_trips_dashboard.tripDetails.newProduct.buttons.train"
            )}. ${t(
              "upcoming_trips_dashboard.upcomingTrips.newBook.externalLink"
            )}`}
            data-testid="external-book-train-button"
          />
        )}
    </Flex>
  );
}

export default ShowRail;
