/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addBookingActions: [] as string[],
};

const tripAddBookingSlice = createSlice({
  name: "tripAddBooking",
  initialState,
  reducers: {
    setTripAddBookingActions(state, action) {
      state.addBookingActions = action.payload;
    },
  },
});

export const tripAddBookingActions = tripAddBookingSlice.actions;

export default tripAddBookingSlice.reducer;
