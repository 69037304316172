/* eslint-disable no-debugger */
import {
  Button,
  ChevronStartIcon,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectFeatureToggle } from "../../../../store/feature-toggles/FeatureTogglesSelector";
import { useAppSelector } from "../../../../store/StoreHooks";
import { FeatureToggleDefinition } from "../../../../utils/constants";
import { useStore } from "../../context/ShareMyRideContext";
import { types } from "../../context/ShareMyRideReducer";
import {
  getDisabledTextContent,
  getEmptyPeopleList,
  getErrorTextContent,
  getExpirationTextContent,
} from "../../utils/shareMyRideUtils";
import EnableHotelShareToggle from "../enable-hotel-share-toggle/EnableHotelShareToggle";
import PeopleList from "../people-list/PeopleList";
import stylesShareMyRideContainer from "../share-my-ride-container/ShareMyRideContainer.module.scss";

function ListContent({ params }: any) {
  const { sharedInfo, dispatch, isMobile, themeName, rows, header } = params;
  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide",
  });
  const { activeSegment, productsToShare } = useStore();
  const [infoMsg, setInfoMsg] = useState<any>();
  const [viewTable, setViewTable] = useState<boolean>(false);
  const activeProduct = productsToShare?.filter(
    (prod: any) => prod.date === activeSegment.date
  )[0];

  const isSameHotelActivated = useAppSelector((state) =>
    selectFeatureToggle(
      state,
      FeatureToggleDefinition.sameHotelForShareMyTransfer.id
    )
  );

  useEffect(() => {
    if (activeProduct?.error) {
      setViewTable(false);
      setInfoMsg(getErrorTextContent(isMobile));
    } else if (!activeSegment) {
      setViewTable(false);
      setInfoMsg(getExpirationTextContent());
    } else if (!activeSegment.sharingEnabled) {
      setViewTable(false);
      const { numberOfPeopleSharing } = activeSegment;
      setInfoMsg(
        getDisabledTextContent(
          numberOfPeopleSharing,
          isMobile,
          themeName,
          activeSegment?.locationType
        )
      );
    } else if (
      activeSegment.sharingEnabled &&
      activeSegment.peopleSharing.length === 0
    ) {
      setViewTable(false);
      setInfoMsg(getEmptyPeopleList(isMobile));
    } else {
      setViewTable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSegment, viewTable, dispatch, activeProduct, themeName]);

  const tripInfo = () => (
    <Flex column>
      <Text
        content={activeSegment.productDetails.productTitle}
        weight="semibold"
        className={stylesShareMyRideContainer.mainInfo}
      />
      <Text
        content={activeSegment.formattedDate}
        className={stylesShareMyRideContainer.mainInfo}
      />
    </Flex>
  );

  return (
    <Flex column className={stylesShareMyRideContainer.listContent}>
      <Flex
        gap={isMobile ? "gap.medium" : "gap.small"}
        vAlign="start"
        space="between"
        column={isMobile}
      >
        <Flex gap="gap.small">
          {sharedInfo.length > 1 && (
            <Button
              icon={<ChevronStartIcon size="large" />}
              text
              size="small"
              content={t("back")}
              onClick={() => {
                dispatch({ type: types.setActiveSegment, payload: null });
              }}
              className={stylesShareMyRideContainer.backButton}
            />
          )}
          {activeSegment && tripInfo()}
        </Flex>
        {isSameHotelActivated?.isActive && (
          <Flex data-joyride-id="shareTransferEnableHotelShare">
            <EnableHotelShareToggle
              isMobile={isMobile}
              hotelShareToggleChecked={activeSegment.hotelSharingEnabled}
              activeSegment={activeSegment}
              activeProduct={activeProduct}
              className={
                isMobile
                  ? stylesShareMyRideContainer.enableAllToggleContainerMobile
                  : ""
              }
            />
          </Flex>
        )}
      </Flex>

      <Flex.Item align="stretch">
        {viewTable ? (
          <PeopleList
            data-testid="listPeopleSharing"
            rows={rows}
            header={header}
            label={t("tableLabel")}
          />
        ) : (
          <Text
            content={infoMsg}
            className={stylesShareMyRideContainer.emptyList}
          />
        )}
      </Flex.Item>
    </Flex>
  );
}

export default ListContent;
