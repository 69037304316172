import {
  ShareNyRideAction,
  SharingFlightModel,
  SharingRailModel,
  SMRStateModel,
} from "../models/ShareMyRideContext.model";
import { matchArrivalDate, updateProductsToShare } from "./ReducerUtils";

const types = {
  setTrip: "setTrip",
  updateTrip: "updateTrip",
  enableShareTrip: "enableShareTrip",
  disableShareTrip: "disableShareTrip",
  setPeopleSharing: "setPeopleSharing",
  setLoading: "setLoading",
  setError: "setError",
  setActiveSegment: "setActiveSegment",
  setGlobalLoading: "setGlobalLoading",
  setErrorEnableAll: "setErrorEnableAll",
  setCommonError: "setCommonError",
  setErrorEnableHotelShare: "setErrorEnableHotelShare",
};

const shareMyRideReducer = (
  state: SMRStateModel,
  action: ShareNyRideAction
) => {
  switch (action.type) {
    case types.setTrip:
      return {
        ...state,
        tripId: action.payload.tripId,
        productsToShare: action.payload.productsToShare.map((product: any) => {
          const currentProductDetails:
            | SharingFlightModel
            | SharingRailModel
            | undefined =
            product.type === "AIR"
              ? {
                  arrivalAirportName: product.productDetails.arrivalAirportName,
                  departureAirportName:
                    product.productDetails.departureAirportName,
                  locationCode: product.productDetails.locationCode,
                  flightReference: product.productDetails.flightReference,
                  prevFlightReference:
                    product.productDetails.prevFlightReference,
                  terminal: product.productDetails.terminal,
                  productTitle: product.productDetails.productTitle,
                  location: product.productDetails.location,
                  arrivalIataCode: product.productDetails.arrivalIataCode,
                  departureIataCode: product.productDetails.departureIataCode,
                  arrivalDateTime: product.productDetails.arrivalDateTime,
                  departureDateTime: product.productDetails.departureDateTime,
                  serviceProviderInfo:
                    product.productDetails.serviceProviderInfo,
                  hotel: product.productDetails.hotel,
                }
              : {
                  arrivalStation: product.productDetails.arrivalStation,
                  departureStation: product.productDetails.departureStation,
                  serviceProvider: product.productDetails.serviceProvider,
                  vehicleNumber: product.productDetails.vehicleNumber,
                  prevServiceProvider:
                    product.productDetails.prevServiceProvider,
                  prevVehicleNumber: product.productDetails.prevVehicleNumber,
                  locationCode: product.productDetails.locationCode,
                  productTitle: product.productDetails.productTitle,
                  location: product.productDetails.location,
                  arrivalDateTime: product.productDetails.arrivalDateTime,
                  departureDateTime: product.productDetails.departureDateTime,
                  serviceProviderInfo:
                    product.productDetails.serviceProviderInfo,
                  hotel: product.productDetails.hotel,
                };

          return {
            type: product.type,
            date: product.date,
            formattedDate: product.formattedDate,
            productDetails: currentProductDetails,
            sharingEnabled: false,
            numberOfPeopleSharing: 0,
            loading: false,
            error: false,
            locationType: product.locationType,
          };
        }),
      };
    case types.updateTrip:
      return updateProductsToShare(state, (product) =>
        matchArrivalDate(product, action.payload.date, {
          ...product,
          sharingEnabled: action.payload.userIsSharing,
          numberOfPeopleSharing: action.payload.numberUsersSharing,
          hotelSharingEnabled: action.payload.userIsSharingHotel,
        })
      );

    case types.enableShareTrip:
      return updateProductsToShare(state, (product) =>
        matchArrivalDate(product, action.payload.date, {
          ...product,
          sharingEnabled: true,
        })
      );
    case types.setPeopleSharing:
      return updateProductsToShare(state, (product) =>
        matchArrivalDate(product, action.payload.date, {
          ...product,
          peopleSharing: action.payload.peopleSharing,
          loading: false,
          error: false,
        })
      );
    case types.disableShareTrip:
      return updateProductsToShare(state, (product) =>
        matchArrivalDate(product, action.payload.date, {
          ...product,
          sharingEnabled: false,
          productDetails: [],
        })
      );
    case types.setLoading:
      return updateProductsToShare(state, (product) =>
        matchArrivalDate(product, action.payload.date, {
          ...product,
          loading: action.payload.loading,
        })
      );
    case types.setError:
      return updateProductsToShare(state, (product) =>
        matchArrivalDate(product, action.payload.date, {
          ...product,
          error: true,
        })
      );
    case types.setActiveSegment:
      return {
        ...state,
        activeSegment: action.payload,
      };
    case types.setGlobalLoading:
      return {
        ...state,
        globalLoading: action.payload,
      };
    case types.setErrorEnableAll:
      return {
        ...state,
        errorEnableAll: action.payload,
      };
    case types.setCommonError:
      return {
        ...state,
        commonError: action.payload,
      };
    case types.setErrorEnableHotelShare:
      return {
        ...state,
        errorEnableHotelShare: action.payload,
      };
    default:
      return state;
  }
};

export { types };
export default shareMyRideReducer;
