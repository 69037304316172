import { SegmentType } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";

export default function HandleSegmentPermissions(
  permission: SegmentType,
  blacklisted: boolean,
  addBooking: boolean
) {
  if (addBooking && !blacklisted && permission) {
    return true;
  }

  return false;
}
