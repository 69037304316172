/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from "@fluentui/react-northstar";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CancelContext from "../../../upcoming-trips-dashboard/cancel/CancelContext";
import { useDispatch, useStore } from "../../context/ShareMyRideContext";
import { types } from "../../context/ShareMyRideReducer";
import getEnableParams from "../../utils/GetEnableParams";
import { appInsightsTracker } from "../../utils/shareMyRideUtils";
import { hotelShare } from "../share-my-ride-container/functions/shareMyRideApi";
import Styles from "./EnableHotelShareToggle.module.scss";

function EnableHotelShareToggle({
  isMobile,
  hotelShareToggleChecked,
  activeSegment,
  activeProduct,
}: any) {
  const dispatch = useDispatch();
  const { tripId } = useStore();

  const { errorEnableHotelShare, commonError } = useStore();
  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide",
  });

  useEffect(() => {
    if (Object.keys(activeSegment?.productDetails?.hotel || {}).length === 0) {
      dispatch({
        type: types.setErrorEnableHotelShare,
        payload: "errorEnableHotelShare",
      });
    } else {
      dispatch({
        type: types.setErrorEnableHotelShare,
        payload: "",
      });
    }
  }, [activeSegment]);

  const enableParamsForHotel = getEnableParams(activeSegment, true);

  const callAppInsights = (isEnabled: boolean) => {
    appInsightsTracker(
      { name: "travel-shareHotel-activation" },
      {
        tripId,
        isActivated: !isEnabled,
      }
    );
  };

  const handleHotelShare = (isEnabled: boolean) => {
    const polling = "POST";
    if (enableParamsForHotel.locationCode) {
      dispatch({ type: types.setGlobalLoading, payload: true });
      hotelShare(
        tripId,
        enableParamsForHotel,
        dispatch,
        polling,
        activeProduct,
        !isEnabled
      );
    } else {
      dispatch({
        type: types.setErrorEnableHotelShare,
        payload: isEnabled ? "errorDisableHotelShare" : "errorEnableHotelShare",
      });
    }
    callAppInsights(isEnabled);
  };

  const cancelContext = useContext(CancelContext);

  return (
    <Checkbox
      label={t("enableHotelShareLabel")}
      labelPosition="start"
      data-testid="enableHotelShare-toggle"
      toggle
      checked={hotelShareToggleChecked}
      disabled={
        errorEnableHotelShare === "errorEnableHotelShare" ||
        errorEnableHotelShare === "errorDisableHotelShare" ||
        commonError ||
        !activeSegment.sharingEnabled
      }
      className={
        isMobile
          ? Styles.enableHotelShareToggleMobile
          : Styles.enableHotelShareToggle
      }
      onClick={() => handleHotelShare(hotelShareToggleChecked)}
      aria-hidden={cancelContext.cancelPopup}
    />
  );
}

export default EnableHotelShareToggle;
