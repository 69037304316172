import { Flex, Text } from "@fluentui/react-northstar";
import { useState } from "react";
import useScreenSize from "../../../../common/components/responsive/useScreenSize";
import i18next from "../../../../i18n/i18n";
import EnableAllToggle from "../enable-all-toggle/EnableAllToggle";
import InfoDialog from "../info-dialog/InfoDialog";
import stylesShareMyRideContainer from "../share-my-ride-container/ShareMyRideContainer.module.scss";

function DestinationCity({
  activeSegment,
  segmentsLength,
  toggleChecked,
}: any) {
  const tablet = 640;
  const screenSize = useScreenSize();
  const isMobile = screenSize < tablet;
  const [open, setOpen] = useState(false);
  const { t } = i18next;

  const openPopUp = (e: any) => {
    if (e.which === 13 || e.which === 32) {
      setOpen(true);
    }
  };

  return (
    <Flex column={isMobile}>
      <div
        className={
          !isMobile && segmentsLength !== 0
            ? stylesShareMyRideContainer.smrSubtitleContainer
            : ""
        }
      >
        <Text
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.foreground1,
          })}
          className={isMobile ? stylesShareMyRideContainer.mainInfoMobile : ""}
        >
          {t("shareMyRide.mainInfo")}
        </Text>
        <Text
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.brand.foreground1,
          })}
          className={stylesShareMyRideContainer.moreInfo}
          onClick={() => setOpen(true)}
          onKeyPress={openPopUp}
          role="button"
          tabIndex={0}
        >
          <u>{t("shareMyRide.moreInfoLink")}</u>
        </Text>
      </div>
      <InfoDialog open={open} setOpen={setOpen} />
      {!activeSegment && segmentsLength !== 0 && (
        <Flex data-joyride-id="shareTransferEnableAll">
          <EnableAllToggle
            isMobile={isMobile}
            toggleChecked={toggleChecked}
            className={
              isMobile
                ? stylesShareMyRideContainer.enableAllToggleContainerMobile
                : ""
            }
          />
        </Flex>
      )}
    </Flex>
  );
}

export default DestinationCity;
