/* eslint-disable import/prefer-default-export */

import { RootState } from "../StoreHooks";

export const hasAddAir = (state: RootState): boolean =>
    state.tripAddBooking.addBookingActions.includes("ADDAIR");

export const hasAddCar = (state: RootState): boolean =>
    state.tripAddBooking.addBookingActions.includes("ADDCAR");

export const hasAddTransfer = (state: RootState): boolean =>
    state.tripAddBooking.addBookingActions.includes("ADDTRANSFER");

export const hasAddHotel = (state: RootState): boolean =>
    state.tripAddBooking.addBookingActions.includes("ADDHOTEL");

export const hasAddRail = (state: RootState): boolean =>
    state.tripAddBooking.addBookingActions.includes("ADDRAIL");
