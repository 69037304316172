import { SameHotelInfoModel } from "../models/ShareMyRideContext.model";

function getHotelInfoObj(segment: any): string {
  const { name, latitude, longitude, address } =
    segment?.productDetails?.hotel || {};
  const { cityName: city, countryCode: country, postalCode } = address || {};
  const hotelObj: SameHotelInfoModel = {
    name,
    latitude,
    longitude,
    city,
    country,
    postalCode,
  };
  return JSON.stringify(hotelObj);
}

function getEnableParams(segment: any, forHotel = false) {
  const locAdditionalInfo =
    segment.type === "AIR" ? `${segment.productDetails.terminal}` : "";
  return {
    segmentType: forHotel ? "HOTEL" : segment.type,
    date: segment.date,
    reference:
      segment.type === "AIR"
        ? segment.productDetails.flightReference
        : `${segment.productDetails.serviceProvider} ${segment.productDetails.vehicleNumber}`,
    locationAdditionalInfo: forHotel
      ? getHotelInfoObj(segment)
      : locAdditionalInfo,

    locationType: segment.locationType,
    locationCode: segment.productDetails.locationCode,
    prevReference:
      segment.type === "AIR"
        ? segment.productDetails.prevFlightReference
        : `${segment.productDetails.prevServiceProvider} ${segment.productDetails.prevVehicleNumber}`,
  };
}

export default getEnableParams;
